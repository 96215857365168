export function readURL(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      $("#logo-img-tag").attr("src", e.target.result);
    };
    reader.readAsDataURL(input.files[0]);
  }
}
$("#logo-img").on('change',function () {
  readURL(this);
});

export function readPhotoURL(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      $("#photo-img-tag").attr("src", e.target.result);
    };
    reader.readAsDataURL(input.files[0]);
  }
}
$("#photo-img").on('change',function () {
  readPhotoURL(this);
});
