import { listingText } from "./listings";
import { location } from "./location-search.js";
import "./forms.js";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/button";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/carousel";

function mobileViewUpdate() {
  const viewportWidth = $(window).width();
  if (viewportWidth <= 992) {
    var $t = $(this);
    $t.attr({
      "data-src": $t.attr("src"),
    }).removeAttr("src");

    $(".startupPack").addClass("lazyload").addAttr("loading");
  }
}

$(function () {
  location();
  listingText();
  // mobileViewUpdate();
});

  $(".video-link").click(function () {
    var theModal = $(this).data("target");
    const videoSRC = $(this).attr("data-video");
    const videoSRCauto = videoSRC + "?modestbranding=1&rel=0&showinfo=0&html5=1&autoplay=1";
    $(theModal + ' iframe').attr('src', videoSRCauto);
    $(theModal + ' button.close').click(function () {
      $(theModal + ' iframe').attr('src', videoSRC);
    });
  });

  
