import "jquery-autocomplete";
import "../../node_modules/jquery-autocomplete/jquery.autocomplete.css";

export function location() {
  const options = {
    dropdownWidth: "100%",
    itemStyle:{color:'#df3192', width:'100%', "z-index": 1000},
    dropdownStyle: { "border-radius": "8px", "text-align": "left" ,"background": "#df3192", "z-index": 1000},
    // style:{background:'red'},
    // hintStyle: {"background": "#df3192"},
    appendMethod: "replace",
    valid: function() {
      return true;
    },
    valueKey: "id",
    titleKey: "name",
    limit: 10,
    source: [
      function(q, add) {
        if(!q) {
          return;
        }
        let url;
        if (SYOB.country.toLowerCase() === "uk" || SYOB.country === null) {
          url = SYOB.endpoints.ukLocationsApi;
        } else {
          url = SYOB.endpoints.countryLocationsApi;
        }

        $('#areasWorldButton').addClass('btn disabled');
        jQuery
          .getJSON(
            url + "?format=json&query=" + encodeURIComponent(q),
            function(data) {
              const suggestions = [];
              if (data) {
                jQuery.each(data, function(i, val) {
                  suggestions.push({ value: val["id"], title: val["name"] });
                });
                add(suggestions);
              }
            }
          )
          .fail(function( jqxhr, textStatus, error ) {
            var err = textStatus + ", " + error;
            console.log( "Request Failed: " + error );
          });
      }
    ],
    getTitle: function(item) {
      return item.title;
    },
    getValue: function(item) {
      return item.title;
    }
  };

  $(".local-area > .autocomplete")
    .autocomplete(options)
    .on("selected.xdsoft", function(e, datum) {
      if (SYOB.country.toLowerCase() === "uk" || SYOB.country === null) {
        var url = SYOB.endpoints.business.replace("place", datum.title);
      } else {
        var url = SYOB.endpoints.business.replace(
          "place",
          datum.title + "/" + datum.value
        );
      }
      window.location.href = url;
    });

  $("#free-listing-form .autocomplete")
    .autocomplete(options)
    .on("selected.xdsoft", function(e, datum) {
      $("#freeListingLocationId").val(datum.value);
      
    });

  $("#signup-form .autocomplete")
    .autocomplete(options)
    .on("selected.xdsoft", function(e, datum) {
      const ref = $("#signup-form").find(":input#locationid");
      ref[0].value = datum.value;
    });

  $("#add-sponsorships .location-search")
    .autocomplete(options)
    .on("selected.xdsoft", function(e, datum) {
      // find jquery reference to the locationid hidden field
      const ref = $("#add-sponsorships").find(":input#locationid");
      // set the value of the hidden field to the id of the location selected
      ref[0].value = datum.value;
    });

  $("#adminUserLocationChange .autocomplete")
    .autocomplete(options)
    .on("selected.xdsoft", function(e, datum) {
      // find jquery reference to the locationid hidden field
      const ref = $("#adminUserLocationChange").find(":input#locationid");
      // set the value of the hidden field to the id of the location selected
      ref[0].value = datum.value;
    });
}
