export function listingText() {
  $("div.show-more").each(function(i) {
    const link = $(this);
    const content = link.siblings("div.text-content");
    // if (typeof content[0] !== "undefined") {
    //   const visibleHeight = content[0].clientHeight;
    //   const actualHeight = content[0].scrollHeight - 1;
    //   if (actualHeight > visibleHeight) {
    //     $(this).show();
    //   } else {
    //     $(this).hide();
    //   }
    // }
  });

  $(".show-more a").on("click", function() {
    const link = $(this);
    const content = link.parent().prev("div.text-content");
    const linkText = link.text();
    content.toggleClass("short-text, full-text");
    link.text(getShowLinkText(linkText));
    return false;
  });
}

export function getShowLinkText(currentText) {
  let newText = "";
  if (currentText.toUpperCase() === "(+)") {
    newText = "(-)";
  } else {
    newText = "(+)";
  }
  return newText;
}
